<template>
  <item-card>    
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Atlikumi
      </h3>
    </template>

    <template v-slot:content>

      <!-- Display  all stock items -->
      <template v-for="(item, index) in stockItems" :key="index">
        <SingleStockItemDetails :item="item" :category="category" />
      </template>
    </template>

  </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import { defineAsyncComponent } from 'vue'

const SingleStockItemDetails = defineAsyncComponent(() =>
  import('@/components/Stock/StockItems/SingleStockItemDetails'))

export default {
  name: "ShowStockItemDetails",
  components: {
    ItemCard,
    SingleStockItemDetails,
  },
  props: ['stockItems', 'category'],
}
</script>