<template>
    <Content :filter="true" :showFilterDefault="true">
        <template v-slot:topbar>
            <div class="flex h-full items-center">
            </div>
        </template>

        <template v-slot:filter>
            <div class="flex flex-wrap items-center">
                <button type="button"
                        @click="getReportExcel"
                        class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
                    Drukāt Excel
                </button>

            </div>
        </template>

        <template v-slot:content>
            <template v-if="stockItems && stockItems.data.length > 0">
                <div class="sm:rounded-md mb-3">
                    <ul role="list" class="">
                        <template v-for="(item, index) in stockItems.data" :key="index">
                            <StockItemListItem :item="item"/>
                        </template>
                    </ul>
                </div>

                <Pagination :meta="stockItems.meta" :onPageChange="onPageChange"/>
            </template>

            <template v-else>
                <p class="py-6 text-center dark:text-gray-300">
                    Diemžēl nekas netika atrasts
                </p>
            </template>
        </template>
    </Content>
</template>

<script>
import {debounce} from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import StockItemListItem from "@/components/Stock/StockItems/StockItemListItem"
import Pagination from "@/components/Components/Pagination";

export default {
    name: "StockItems",
    components: {
        Pagination,
        StockItemListItem
    },
    created() {
        this.$store.dispatch("setDataLoading", true)
    },
    mounted() {
        this.$store.dispatch('resetSearch')
        const s = Search.getSearchQuery();
        if (s) {
            this.$store.dispatch('changeSearch', s)
            this.searchStockItems()
        } else {
            this.$store.dispatch('getStockItems', {
                page: Search.getCurrentPage(),
            })
        }
    },
    computed: {
        ...mapGetters({
            stockItems: 'stockItems',
            search: 'search',
        })
    },
    watch: {
        search: function () {
            this.searchStockItems()
        }
    },
    methods: {
        searchStockItems: debounce(function () {
            this.$store.dispatch('changeSearch', this.search)
            this.$store.dispatch('getStockItems', {
                page: Search.getCurrentPage()
            })
        }, 500),
        onPageChange(page) {
            this.$store.dispatch('getStockItems', {
                page: page
            });
            Search.changePage(this.stockItems.meta.current_page)
        },
        getReportExcel(){
            this.$store.dispatch('getStockItemReportExcel')
        }
    }
}
</script>

<style>

</style>