<template>
  <li>
    <item-card :url="`/catalog/${item.id}`" contentClass="grid grid-cols-12">
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          {{item.name}}
        </h3>
      </template>

      <template v-slot:buttons>

        <template v-if="item.stockItems">
          <Button :icon="showStockItemDetails ? 'chevron-up' : 'chevron-down'" @click="showStockItemDetails = !showStockItemDetails" />
        </template>
      </template>

      <template v-slot:content>

        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <item-text title="Preces kods" :text="item.sku" />
        </div>
        
        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
          <item-text title="Kategorija" :text="item.category.name" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <template v-if="item.stockItems">
            <item-text :title="`Atlikums ${item.measure_unit.name}`" :text="`${item.in_main_customer_stock} `" />
          </template>
          <template v-else>
            <item-text :title="`Atlikums ` + item.measure_unit.short_name" :text="item.in_main_customer_stock" />
          </template>
        </div>

      </template>

      <template v-slot:additional>
        <template v-if="showStockItemDetails">
          <div class="w-full border-b border-gray-200 dark:border-gray-700"></div>

          <ShowStockItemDetails :inventoryItems="item.inventoryItems" :stockItems="item.stockItems" :showStockItemDetails="showStockItemDetails" :category="item.category" />
        </template>
      </template>

    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import ShowStockItemDetails from "@/components/Stock/StockItems/ShowStockItemDetails"

export default {
  name: "StockItemListItem",
  data() {
    return {
      showStockItemDetails: false,
    }
  },
  components: {
    ItemCard,
    ItemText,
    ShowStockItemDetails,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  methods: {
    viewCatalogItem(catalogItemId) {
      this.$router.push({path: '/catalog/' + catalogItemId})
    },
  },
}
</script>

<style>

</style>